import { useState, useEffect, useMemo, useRef } from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import {
    Grid,
    Button,
    IconButton,
    Dialog,
    DialogContent,
    DialogActions,
    DialogTitle,
    AppBar,
    Toolbar,
    Typography
} from '@mui/material';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import PersonRemoveIcon from '@mui/icons-material/PersonRemove';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CloseIcon from '@mui/icons-material/Close';
import { useDispatch } from 'react-redux';
import { addAlert, deleteAlert } from '../../redux/features/alerts/alertsSlice';
import {
    useAssignMentorContactWheelByAdminMutation,
    useAssignMenteeContactWheelByAdminMutation,
    useCancelMentorContactWheelByAdminMutation,
    useCancelMenteeContactWheelByAdminMutation,
} from '../../redux/features/events/eventsApiSlice';

const BlockScheduleContactWheelForm = ({
    handleClose,
    initialValues,
    onSuccess,
}: any) => {
    const dispatch = useDispatch();
    const [assignMentor] = useAssignMentorContactWheelByAdminMutation();
    const [assignMentee] = useAssignMenteeContactWheelByAdminMutation();
    const [cancelMentor] = useCancelMentorContactWheelByAdminMutation();
    const [cancelMentee] = useCancelMenteeContactWheelByAdminMutation();

    const [assignedMentors, setAssignedMentors] = useState<Set<number>>(new Set());
    const [assignedMentees, setAssignedMentees] = useState<Set<number>>(new Set());

    const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
    const [openConfirmRemoveDialog, setOpenConfirmRemoveDialog] = useState(false);
    const [emailToConfirm, setEmailToConfirm] = useState('');
    const [indexToConfirm, setIndexToConfirm] = useState<number | null>(null);
    const [isMentor, setIsMentor] = useState(true); 

    
    const backButtonRef = useRef<HTMLButtonElement>(null);
    const assignButtonRefs = useRef<(HTMLButtonElement | null)[]>([]);
    const removeButtonRefs = useRef<(HTMLButtonElement | null)[]>([]);

    const validationSchema = Yup.object().shape({
        mentors: Yup.array().of(
            Yup.object().shape({
                mentorEmail: Yup.string().email("Correo inválido"),
            })
        ),
        mentees: Yup.array().of(
            Yup.object().shape({
                menteeEmail: Yup.string().email("Correo inválido"),
            })
        ),
    });

    const defaultValues = {
        mentors: [],
        mentees: [],
    };

    const [formValues, setFormValues] = useState({ ...defaultValues, ...initialValues });

    useEffect(() => {
      
        setFormValues({ ...defaultValues, ...initialValues });
    }, [initialValues]);

    const mentors = useMemo(() => formValues.mentors || [], [formValues.mentors]);
    const mentees = useMemo(() => formValues.mentees || [], [formValues.mentees]);
    const mentorsCount = initialValues.mentorsCount || 1;
    const activityId = initialValues.activityId;

    useEffect(() => {
        const initialMentorSet = new Set<number>(
            mentors
                .map((_: any, index: number) => index)
                .filter((index: number) => mentors[index]?.mentorEmail)
        );
        const initialMenteeSet = new Set<number>(
            mentees
                .map((_: any, index: number) => index)
                .filter((index: number) => mentees[index]?.menteeEmail)
        );

        setAssignedMentors(initialMentorSet);
        setAssignedMentees(initialMenteeSet);
    }, [mentors, mentees]);

  
    const handleCloseForm = () => {
        handleClose();
        if (backButtonRef.current) {
            backButtonRef.current.focus();
        }
    };

    return (
        <>
            <Dialog
                open
                fullWidth
                maxWidth="md"
                onClose={handleCloseForm}
               
                disableEnforceFocus={false}
                sx={{ width: '90vw', maxWidth: '1200px' }}
            >
                <AppBar sx={{ position: 'relative' }}>
                    <Toolbar>
                        <IconButton edge="start" color="inherit" onClick={handleCloseForm} aria-label="close">
                            <CloseIcon />
                        </IconButton>
                        <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                            Modificar Bloque de Horario
                        </Typography>
                    </Toolbar>
                </AppBar>
                <DialogContent>
                    <Formik
                        initialValues={formValues}
                        validationSchema={validationSchema}
                        onSubmit={() => {}}
                        enableReinitialize
                    >
                        {({ values, handleChange, setFieldValue }) => {
                            const handleConfirmAssign = async () => {
                                if (emailToConfirm && indexToConfirm !== null) {
                                    let loadingAlertId = `loading_${Date.now()}`;
                                    let successAlertTitle = isMentor ? 'Mentor asignado con éxito' : 'Mentee asignado con éxito';
                                    let errorAlertTitle = isMentor ? 'Error al asignar mentor' : 'Error al asignar mentee';

                                    let loadingAlert = {
                                        id: loadingAlertId,
                                        type: "loading",
                                        title: isMentor ? "Asignando mentor..." : "Asignando mentee...",
                                        desc: "",
                                        close: false,
                                        timeout: 2000
                                    };
                                    dispatch(addAlert({ alert: loadingAlert }));

                                    try {
                                        if (isMentor && !assignedMentors.has(indexToConfirm)) {
                                            await assignMentor({ email: emailToConfirm, activityId }).unwrap();
                                            setAssignedMentors((prev) => new Set(prev).add(indexToConfirm));
                                            
                                            setFieldValue(`mentors[${indexToConfirm}].mentorEmail`, emailToConfirm);
                                        } else if (!isMentor && !assignedMentees.has(indexToConfirm)) {
                                            await assignMentee({ email: emailToConfirm, activityId }).unwrap();
                                            setAssignedMentees((prev) => new Set(prev).add(indexToConfirm));
                                             
                                            setFieldValue(`mentees[${indexToConfirm}].menteeEmail`, emailToConfirm);
                                        }

                                        dispatch(deleteAlert({ alert_id: loadingAlertId }));

                                        let successAlert = {
                                            id: `success_${Date.now()}`,
                                            type: "success",
                                            title: successAlertTitle,
                                            desc: "",
                                            close: true,
                                            timeout: 2000
                                        };
                                        dispatch(addAlert({ alert: successAlert }));

                                        onSuccess();
                                    } catch (error) {
                                        dispatch(deleteAlert({ alert_id: loadingAlertId }));

                                        let errorAlert = {
                                            id: `error_${Date.now()}`,
                                            type: "error",
                                            title: errorAlertTitle,
                                            desc: "",
                                            close: true,
                                            timeout: 2000
                                        };
                                        dispatch(addAlert({ alert: errorAlert }));
                                        console.error('Error al asignar:', error);
                                    }
                                }
                                setOpenConfirmDialog(false);
                            };

                            const handleConfirmRemove = async () => {
                                if (emailToConfirm && indexToConfirm !== null) {
                                    let loadingAlertId = `loading_${Date.now()}`;
                                    let successAlertTitle = isMentor ? 'Mentor desinscrito con éxito' : 'Mentee desinscrito con éxito';
                                    let errorAlertTitle = isMentor ? 'Error al desinscribir mentor' : 'Error al desinscribir mentee';

                                    let loadingAlert = {
                                        id: loadingAlertId,
                                        type: "loading",
                                        title: isMentor ? "Desinscribiendo mentor..." : "Desinscribiendo mentee...",
                                        desc: "",
                                        close: false,
                                        timeout: 2000
                                    };
                                    dispatch(addAlert({ alert: loadingAlert }));

                                    try {
                                        if (isMentor && assignedMentors.has(indexToConfirm)) {
                                            await cancelMentor({ email: emailToConfirm, activityId }).unwrap();
                                            setAssignedMentors((prev) => {
                                                const updated = new Set(prev);
                                                updated.delete(indexToConfirm);
                                                return updated;
                                            });
                                           
                                            setFieldValue(`mentors[${indexToConfirm}].mentorEmail`, '');
                                        } else if (!isMentor && assignedMentees.has(indexToConfirm)) {
                                            await cancelMentee({ email: emailToConfirm, activityId }).unwrap();
                                            setAssignedMentees((prev) => {
                                                const updated = new Set(prev);
                                                updated.delete(indexToConfirm);
                                                return updated;
                                            });
                                          
                                            setFieldValue(`mentees[${indexToConfirm}].menteeEmail`, '');
                                        }

                                        dispatch(deleteAlert({ alert_id: loadingAlertId }));

                                        let successAlert = {
                                            id: `success_${Date.now()}`,
                                            type: "success",
                                            title: successAlertTitle,
                                            desc: "",
                                            close: true,
                                            timeout: 2000
                                        };
                                        dispatch(addAlert({ alert: successAlert }));

                                        onSuccess();
                                    } catch (error) {
                                        dispatch(deleteAlert({ alert_id: loadingAlertId }));

                                        let errorAlert = {
                                            id: `error_${Date.now()}`,
                                            type: "error",
                                            title: errorAlertTitle,
                                            desc: "",
                                            close: true,
                                            timeout: 2000
                                        };
                                        dispatch(addAlert({ alert: errorAlert }));
                                        console.error('Error al desinscribir:', error);
                                    }
                                }
                                setOpenConfirmRemoveDialog(false);
                            };

                            const handleAssign = (email: string, index: number, isMentorRole: boolean) => {
                                setEmailToConfirm(email);
                                setIndexToConfirm(index);
                                setIsMentor(isMentorRole);
                                setOpenConfirmDialog(true);
                            };

                            const handleRemove = (email: string, index: number, isMentorRole: boolean) => {
                                setEmailToConfirm(email);
                                setIndexToConfirm(index);
                                setIsMentor(isMentorRole);
                                setOpenConfirmRemoveDialog(true);
                            };

                         
                            assignButtonRefs.current = [];
                            removeButtonRefs.current = [];

                            return (
                                <>
                                    <Form>
                                        <p style={{ fontStyle: 'italic', fontWeight: 'bold', marginBottom: '10px' }}>
                                            Los correos para asignar horarios deben ser los registrados en EtMmeet
                                        </p>
                                        <Grid container spacing={2}>
                                            {/* Columna para los mentores */}
                                            <Grid item xs={12} md={6}>
                                                <Typography variant="subtitle1" gutterBottom style={{ fontWeight: 'bold' }} color="primary">
                                                    Mentores
                                                </Typography>
                                                {Array.from({ length: mentorsCount }).map((_, index: number) => (
                                                    <div key={`mentor-${index}`} style={{ marginBottom: '10px' }}>
                                                        <Grid container spacing={2} alignItems="flex-end">
                                                            <Grid item xs={12} sm={8}>
                                                                <input
                                                                    name={`mentors[${index}].mentorEmail`}
                                                                    style={{ width: '100%' }}
                                                                    placeholder="Sin asignar, ingrese correo..."
                                                                    value={values.mentors[index]?.mentorEmail || ''}
                                                                    onChange={handleChange}
                                                                    disabled={assignedMentors.has(index)}
                                                                />
                                                            </Grid>
                                                            <Grid
                                                                item
                                                                xs={12}
                                                                sm={4}
                                                                sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}
                                                            >
                                                                {assignedMentors.has(index) ? (
                                                                    <IconButton
                                                                        sx={{ marginRight: '8px' }}
                                                                        onClick={() => handleRemove(values.mentors[index]?.mentorEmail, index, true)}
                                                                        ref={(el) => (removeButtonRefs.current[index] = el)}
                                                                    >
                                                                        <PersonRemoveIcon style={{ color: 'black' }} />
                                                                    </IconButton>
                                                                ) : (
                                                                    <IconButton
                                                                        sx={{ marginRight: '8px' }}
                                                                        onClick={() => handleAssign(values.mentors[index]?.mentorEmail, index, true)}
                                                                        ref={(el) => (assignButtonRefs.current[index] = el)}
                                                                    >
                                                                        <PersonAddIcon style={{ color: 'black' }} />
                                                                    </IconButton>
                                                                )}
                                                                <IconButton
                                                                    onClick={() =>
                                                                        console.log(
                                                                            `Check, activity id: ${activityId}, correo: ${
                                                                                values.mentors[index]?.mentorEmail || 'vacío'
                                                                            }`
                                                                        )
                                                                    }
                                                                >
                                                                    <CheckCircleIcon style={{ color: 'black' }} />
                                                                </IconButton>
                                                            </Grid>
                                                        </Grid>
                                                    </div>
                                                ))}
                                            </Grid>

                                            {/* Columna para los mentees */}
                                            <Grid item xs={12} md={6}>
                                                <Typography variant="subtitle1" gutterBottom style={{ fontWeight: 'bold' }} color="primary">
                                                    Mentees
                                                </Typography>
                                                {Array.from({ length: mentorsCount }).map((_, index: number) => (
                                                    <div key={`mentee-${index}`} style={{ marginBottom: '10px' }}>
                                                        <Grid container spacing={2} alignItems="flex-end">
                                                            <Grid item xs={12} sm={8}>
                                                                <input
                                                                    name={`mentees[${index}].menteeEmail`}
                                                                    style={{ width: '100%' }}
                                                                    placeholder="Sin asignar, ingrese correo..."
                                                                    value={values.mentees[index]?.menteeEmail || ''}
                                                                    onChange={handleChange}
                                                                    disabled={assignedMentees.has(index)}
                                                                />
                                                            </Grid>
                                                            <Grid
                                                                item
                                                                xs={12}
                                                                sm={4}
                                                                sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}
                                                            >
                                                                {assignedMentees.has(index) ? (
                                                                    <IconButton
                                                                        sx={{ marginRight: '8px' }}
                                                                        onClick={() => handleRemove(values.mentees[index]?.menteeEmail, index, false)}
                                                                        ref={(el) => (removeButtonRefs.current[index] = el)}
                                                                    >
                                                                        <PersonRemoveIcon style={{ color: 'black' }} />
                                                                    </IconButton>
                                                                ) : (
                                                                    <IconButton
                                                                        sx={{ marginRight: '8px' }}
                                                                        onClick={() => handleAssign(values.mentees[index]?.menteeEmail, index, false)}
                                                                        ref={(el) => (assignButtonRefs.current[index] = el)}
                                                                    >
                                                                        <PersonAddIcon style={{ color: 'black' }} />
                                                                    </IconButton>
                                                                )}
                                                                <IconButton
                                                                    onClick={() =>
                                                                        console.log(
                                                                            `Check, activity id: ${activityId}, correo: ${
                                                                                values.mentees[index]?.menteeEmail || 'vacío'
                                                                            }`
                                                                        )
                                                                    }
                                                                >
                                                                    <CheckCircleIcon style={{ color: 'black' }} />
                                                                </IconButton>
                                                            </Grid>
                                                        </Grid>
                                                    </div>
                                                ))}
                                            </Grid>
                                        </Grid>

                                        <div className="d-flex justify-content-end mt-3">
                                            <Button
                                                type="button"
                                                variant="contained"
                                                color="primary"
                                                onClick={handleCloseForm}
                                                ref={backButtonRef}
                                            >
                                                Volver
                                            </Button>
                                        </div>
                                    </Form>

                                    {/* Modal de confirmación para asignación */}
                                    <Dialog
                                        open={openConfirmDialog}
                                        onClose={() => setOpenConfirmDialog(false)}
                                        TransitionProps={{
                                            onExited: () => {
                                                const assignButton = assignButtonRefs.current[indexToConfirm!];
                                                if (assignButton) {
                                                    assignButton.focus();
                                                }
                                            },
                                        }}
                                    >
                                        <DialogTitle>Confirmación</DialogTitle>
                                        <DialogContent>
                                            <p>¿Estás seguro de que deseas añadir al {isMentor ? 'mentor' : 'mentee'}?</p>
                                        </DialogContent>
                                        <DialogActions>
                                            <Button onClick={handleConfirmAssign} color="primary" autoFocus>
                                                Confirmar
                                            </Button>
                                            <Button
                                                onClick={() => setOpenConfirmDialog(false)}
                                                color="primary"
                                            >
                                                Cancelar
                                            </Button>
                                        </DialogActions>
                                    </Dialog>

                                    {/* Modal de confirmación para desinscripción */}
                                    <Dialog
                                        open={openConfirmRemoveDialog}
                                        onClose={() => setOpenConfirmRemoveDialog(false)}
                                        TransitionProps={{
                                            onExited: () => {
                                                const removeButton = removeButtonRefs.current[indexToConfirm!];
                                                if (removeButton) {
                                                    removeButton.focus();
                                                }
                                            },
                                        }}
                                    >
                                        <DialogTitle>Confirmación</DialogTitle>
                                        <DialogContent>
                                            <p>¿Estás seguro de que deseas desinscribir al {isMentor ? 'mentor' : 'mentee'}?</p>
                                        </DialogContent>
                                        <DialogActions>
                                            <Button onClick={handleConfirmRemove} color="primary" autoFocus>
                                                Confirmar
                                            </Button>
                                            <Button
                                                onClick={() => setOpenConfirmRemoveDialog(false)}
                                                color="primary"
                                            >
                                                Cancelar
                                            </Button>
                                        </DialogActions>
                                    </Dialog>
                                </>
                            );
                        }}
                    </Formik>
                </DialogContent>
            </Dialog>
        </>
    );
};

export default BlockScheduleContactWheelForm;
