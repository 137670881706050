// Archivo: src/components/ActivityCards/ActivityCards.tsx

import React, { useState, useMemo } from 'react';
import {
    Grid,
    Card,
    CardContent,
    Typography,
    Button,
    CardActions,
    Pagination,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    SelectChangeEvent
} from '@mui/material';
import moment from 'moment';
import './ActivityCards.scss';
import { Activity, ActivityCardsProps } from '../../types/types';
import { useDispatch } from 'react-redux';
import { addAlert, deleteAlert } from '../../redux/features/alerts/alertsSlice'; // Importar acciones de alertsSlice

const ActivityCards: React.FC<ActivityCardsProps> = ({ datos, handleEditClick, isConfirmed }) => {
    const dispatch = useDispatch(); // Inicializar dispatch
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [itemsPerPage, setItemsPerPage] = useState<number>(6);
    const [flippedStates, setFlippedStates] = useState<{ [key: number]: boolean }>({});

    const totalPages = Math.ceil(datos.length / itemsPerPage);

    const paginatedData = useMemo(() => {
        const startIndex = (currentPage - 1) * itemsPerPage;
        const endIndex = startIndex + itemsPerPage;
        return datos.slice(startIndex, endIndex);
    }, [currentPage, itemsPerPage, datos]);

    const handlePageChange = (event: React.ChangeEvent<unknown>, value: number) => {
        setCurrentPage(value);
    };

    const handleItemsPerPageChange = (event: SelectChangeEvent<string>) => {
        setItemsPerPage(Number(event.target.value));
        setCurrentPage(1);
    };

    const handleInscription = (activity: Activity) => {
        handleEditClick(activity);
        dispatch(addAlert({
            type: 'success',
            title: 'Inscripción Exitosa',
            desc: '¡Te has inscrito correctamente en la actividad!',
            close: true,
            timeout: 6000
        }));
    };

    const handleFlip = (activityId: number) => {
        setFlippedStates(prevState => ({
            ...prevState,
            [activityId]: !prevState[activityId]
        }));
    };

    return (
        <>
            <Grid container spacing={3} justifyContent="center">
                {paginatedData.length === 0 ? (
                    <Grid item xs={12}>
                        <Typography variant="h6" align="center">
                            No hay actividades para mostrar.
                        </Typography>
                    </Grid>
                ) : (
                    paginatedData.map((activity) => {
                        const isFlipped = flippedStates[activity.activityId] || false;

                        return (
                            <Grid item key={activity.activityId} xs={12} sm={6} md={4}>
                                <div className={`flip-card ${isFlipped ? 'flipped' : ''}`}>
                                    <div className="flip-card-inner">
                                        {/* Cara frontal de la tarjeta */}
                                        <div className="flip-card-front">
                                            <Card className="activity-card__container">
                                                <CardContent className="activity-card__content">
                                                    {/* Título siempre arriba */}
                                                    <Typography variant="h6" component="div" className="activity-card__type">
                                                        {activity.type}
                                                    </Typography>
                                                    {/* Contenido central */}
                                                    <div className="activity-card__middle-content">
                                                        <Typography color="text.secondary" gutterBottom className="activity-card__name">
                                                            {activity.activityName}
                                                        </Typography>
                                                        <div className="activity-card__schedule-container">
                                                            <Typography variant="body2" className="activity-card__schedule">
                                                                <span className="schedule-label">Horario: </span>
                                                                <span className="schedule-time">
                                                                    {moment(activity.startTime).format("HH:mm")} - {moment(activity.endTime).format("HH:mm")}
                                                                </span>
                                                            </Typography>
                                                            <Typography variant="body2" className="activity-card__schedule">
                                                                <span className="block-label">Bloque: </span>
                                                                <span className="block-duration">{activity.blockDuration} minutos</span>
                                                            </Typography>
                                                        </div>
                                                    </div>
                                                </CardContent>
                                                <CardActions className="activity-card__actions">
                                                    <Button
                                                        size="small"
                                                        variant="contained"
                                                        onClick={() => handleInscription(activity)}
                                                        className="activity-card__button inscribirse-button"
                                                        style={{ display: isConfirmed ? 'inline-flex' : 'none' }}
                                                    >
                                                        Inscribirse
                                                    </Button>
                                                    <Button
                                                        size="small"
                                                        variant="contained"
                                                        onClick={() => handleFlip(activity.activityId)}
                                                        className="activity-card__button expertos-button"
                                                    >
                                                        Expertos
                                                    </Button>
                                                </CardActions>
                                            </Card>
                                        </div>
                                        {/* Cara trasera de la tarjeta */}
                                        <div className="flip-card-back">
                                            <Card className="activity-card__container">
                                                <CardContent className="activity-card__content">
                                                    {/* Título siempre arriba en la cara trasera */}
                                                    <Typography variant="h6" component="div" className="expertos-title">
                                                        Expertos
                                                    </Typography>
                                                    {/* Contenido central */}
                                                    <div className="activity-card__middle-content">
                                                        <ul className="expertos-list">
                                                            {activity.mentors && activity.mentors.length > 0
                                                                ? [...activity.mentors].sort().map((mentor, index) => (
                                                                    <li key={index} className="activity-card__expositor">{mentor}</li>
                                                                ))
                                                                : <li className="activity-card__expositor">No hay expertos disponibles.</li>
                                                            }
                                                        </ul>
                                                    </div>
                                                </CardContent>
                                                <CardActions className="activity-card__actions">
                                                    <Button
                                                        size="small"
                                                        variant="contained"
                                                        onClick={() => handleFlip(activity.activityId)}
                                                        className="activity-card__button volver-button"
                                                    >
                                                        Volver
                                                    </Button>
                                                </CardActions>
                                            </Card>
                                        </div>
                                    </div>
                                </div>
                            </Grid>
                        );
                    })
                )}
            </Grid>
            {/* Paginación y Control de Items por Página */}
            <Grid container spacing={2} justifyContent="center" alignItems="center" style={{ marginTop: '20px' }}>
                <Grid item>
                    <FormControl variant="standard" sx={{ minWidth: 120 }}>
                        <InputLabel id="items-per-page-label">Items por página</InputLabel>
                        <Select
                            labelId="items-per-page-label"
                            id="items-per-page"
                            value={itemsPerPage.toString()}
                            onChange={handleItemsPerPageChange}
                            label="Items por página"
                        >
                            <MenuItem value={6}>6</MenuItem>
                            <MenuItem value={12}>12</MenuItem>
                            <MenuItem value={24}>24</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item>
                    <Pagination count={totalPages} page={currentPage} onChange={handlePageChange} color="primary" />
                </Grid>
            </Grid>
        </>
    );

};

export default ActivityCards;
