
import React, { useState } from 'react';
import { Box, FormControl, Select, MenuItem, SelectChangeEvent, CircularProgress } from '@mui/material';
import GenericCard from './GenericCard';
import './GenericCardList.scss';
import '../../styles/common-select.scss';

import { ActionButtonProps } from './GenericCard';

interface GenericCardListProps<T> {
  columns: string[];
  data: (T & { actionButtons?: ActionButtonProps<T>[] })[];
  isLoading?: boolean;
}

const GenericCardList = <T,>({
  columns,
  data,
  isLoading,
}: GenericCardListProps<T>) => {
  const [filters, setFilters] = useState<Record<string, string>>({});

  const handleFilterChange = (column: string) => (event: SelectChangeEvent<string>) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [column]: event.target.value,
    }));
  };

  const filterData = () => {
    return data.filter((item) =>
      Object.entries(filters).every(([key, value]) =>
        value
          ? String(item[key as keyof T]).toLowerCase().includes(value.toLowerCase())
          : true
      )
    );
  };

  const filteredData = filterData();

  return (
    <Box className="generic-card-list-container">
      {isLoading ? (
        <div className="loader-container">
          <CircularProgress />
        </div>
      ) : (
        <>
          {}
          <Box className="filter-container">
            {columns.map((column) => (
              <FormControl
                variant="outlined"
                size="small"
                key={column}
                className="common-form-control"
              >
                <Select
                  value={filters[column] || ''}
                  onChange={handleFilterChange(column)}
                  displayEmpty
                  renderValue={(value) =>
                    value === '' ? `${column}` : value
                  }
                >
                  <MenuItem value="">
                    <em>Todos</em>
                  </MenuItem>
                  {Array.from(
                    new Set(data.map((item) => String(item[column as keyof T])))
                  )
                    .filter((option) => option !== '')
                    .map((option, index) => (
                      <MenuItem key={index} value={option}>
                        {option}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            ))}
          </Box>

          {}
          <Box className="generic-card-list">
            {filteredData.map((item, index) => (
              <GenericCard
                key={index}
                columns={columns}
                data={item}
                actionButtons={item.actionButtons || []}
                isLoading={isLoading}
              />
            ))}
          </Box>
        </>
      )}
    </Box>
  );
};

export default GenericCardList;

export type { ActionButtonProps };
