import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { useGetActivityHistoryByUserQuery, useSendActivityFeedbackMutation } from '../../redux/features/events/eventsApiSlice';
import { useSelector, useDispatch } from "react-redux";
import { selectPersonalData } from '../../redux/features/user/userSlice';
import FeedbackDialog from './FeedbackDialog';
import { addAlert, deleteAlert } from '../../redux/features/alerts/alertsSlice';
import GenericCardList, { ActionButtonProps } from '../../components/GenericCard/GenericCardList';

interface ActivityHistoryProps { }

interface Activity {
    id: number;
    eventName: string;
    activityName: string;
    activityType: string;
    startDate: string;
    endDate: string;
    statusName: string;
    statusId: number;
    mentorName: string;
    menteeName: string;
    feedbackGiven: boolean;
    hasMenteeFeedback: boolean;
    hasMentorFeedback: boolean;
    roleInActivity: string;
    availableTimeId: number;
    counterpartName: string;
}

const ActivityHistory: React.FC<ActivityHistoryProps> = () => {
    const dispatch = useDispatch();
    const user_personal_data = useSelector(selectPersonalData);
    const user_id = user_personal_data.Id;

    const { data: activities = [], refetch, isLoading, error } = useGetActivityHistoryByUserQuery(user_id);

    const [openFeedbackDialog, setOpenFeedbackDialog] = useState(false);
    const [selectedAvailableTimeId, setSelectedAvailableTimeId] = useState<number | null>(null);
    const [counterpartName, setCounterpartName] = useState("");
    const [isMentor, setIsMentor] = useState(false);
    const [sendActivityFeedback] = useSendActivityFeedbackMutation();

    useEffect(() => {
        refetch();
    }, [refetch]);

    const handleEvaluation = (availableTimeId: number, counterpartName: string, isMentorRole: boolean) => {
        setSelectedAvailableTimeId(availableTimeId);
        setCounterpartName(counterpartName);
        setIsMentor(isMentorRole);
        setOpenFeedbackDialog(true);
    };

    const handleFeedbackSubmit = async (values: any) => {
        if (selectedAvailableTimeId !== null) {
            const feedbackData = {
                ...values,
                availableTimeId: selectedAvailableTimeId,
                isMentor: isMentor
            };

            let new_alert = { id: `sending_feedback_${Date.now()}`, type: "loading", title: "Enviando feedback", desc: "", close: false, timeout: 0 };
            dispatch(addAlert({ alert: new_alert }));

            try {
                await sendActivityFeedback(feedbackData).unwrap();
                dispatch(deleteAlert({ alert_id: new_alert.id }));
                let new_alert2 = { id: `feedback_sent_${Date.now()}`, type: "success", title: "Feedback enviado", desc: "El feedback ha sido enviado exitosamente", close: true, timeout: 0 };
                dispatch(addAlert({ alert: new_alert2 }));
                refetch();
            } catch (error) {
                dispatch(deleteAlert({ alert_id: new_alert.id }));
                let new_alert2 = { id: `feedback_error_${Date.now()}`, type: "error", title: "Error al enviar feedback", desc: "Hubo un error al enviar el feedback", close: true, timeout: 0 };
                dispatch(addAlert({ alert: new_alert2 }));
            }
        }
        setOpenFeedbackDialog(false);
    };

    const generateActionButtons = (item: any): ActionButtonProps<any>[] => {
        const actionButtons: ActionButtonProps<any>[] = [];

        const {
            statusId,
            roleInActivity,
            hasMentorFeedback,
            hasMenteeFeedback,
            counterpartName,
            availableTimeId,
        } = item;

        if (statusId === 4) {
            const isDisabledEvaluation =
                (roleInActivity === 'Mentor' && hasMentorFeedback) ||
                (roleInActivity === 'Mentee' && hasMenteeFeedback);

            actionButtons.push({
                label: isDisabledEvaluation ? 'Evaluado' : 'Evaluar',
                color: 'primary',
                onClick: () => {
                    if (!isDisabledEvaluation) {
                        handleEvaluation(availableTimeId, counterpartName, roleInActivity === 'Mentor');
                    }
                },
                disabled: isDisabledEvaluation,
            });
        }

        return actionButtons;
    };

    const prepareDataForCardList = () => {
        return activities.map((activity: Activity) => {
            const fecha = moment(activity.startDate).format("DD-MM-YYYY");
            const hora = `${moment(activity.startDate).format("HH:mm")} - ${moment(activity.endDate).format("HH:mm")}`;

            const item = {
                id: activity.id,
                Evento: activity.eventName,
                Actividad: activity.activityType,
                Temática: activity.activityName,
                Fecha: fecha, // Solo la fecha
                Hora: hora, // Solo las horas
                Contraparte: activity.counterpartName,
                Estado: activity.statusName,
                actionButtons: [] as ActionButtonProps<any>[],
                statusId: activity.statusId,
                hasMentorFeedback: activity.hasMentorFeedback,
                hasMenteeFeedback: activity.hasMenteeFeedback,
                roleInActivity: activity.roleInActivity,
                availableTimeId: activity.availableTimeId,
                counterpartName: activity.counterpartName,
            };
            item.actionButtons = generateActionButtons(item);
            return item;
        });
    };

    const cardListData = prepareDataForCardList();

    const columnsToShow = ["Evento", "Actividad", "Temática", "Fecha", "Hora", "Contraparte", "Estado"];

    return isLoading ? (
        <div className='table-container-loading'>
            <p>Cargando actividades...</p>
        </div>
    ) : error ? (
        <div className='table-container'>
            <p>No hay actividades disponibles.</p>
        </div>
    ) : (
        <div className='card-container' style={{ width: '100%', paddingBottom: '80px', minHeight: '500px' }}>
            <GenericCardList
                columns={columnsToShow}
                data={cardListData}
            />
            <FeedbackDialog
                open={openFeedbackDialog}
                onClose={() => setOpenFeedbackDialog(false)}
                onSubmit={handleFeedbackSubmit}
                counterpartName={counterpartName}
                isMentor={isMentor}
            />
        </div>
    );
};

export default ActivityHistory;
