// src/components/UpcomingActivitiesUser.tsx

import React, { useState, useEffect } from 'react';
import { Tooltip, Button } from '@mui/material';
import GenericCardList, { ActionButtonProps } from '../../components/GenericCard/GenericCardList';
import { useSelector, useDispatch } from 'react-redux';
import { selectPersonalData } from '../../redux/features/user/userSlice';
import moment from 'moment';
import FeedbackDialog from './FeedbackDialog';
import ConfirmationDialog from './ConfirmationDialog'; // Importar ConfirmationDialog
import { addAlert, deleteAlert } from '../../redux/features/alerts/alertsSlice';
import {
    useGetUpcomingActivitiesByUserQuery,
    useSendActivityFeedbackMutation,
    useCancelUpcomingActivityByUserMutation
} from '../../redux/features/events/eventsApiSlice';

interface UpcomingActivitiesUserProps {
    onRefetch?: (refetch: () => void) => void;
}

interface Activity {
    id: number;
    eventName: string;
    activityName: string;
    activityType: string;
    startDate: string;
    endDate: string;
    statusName: string;
    statusId: number;
    menteeName: string;
    mentorName: string;
    availableTimeId: number;
    hasMentorFeedback: boolean;
    hasMenteeFeedback: boolean;
    roleInActivity?: string;
}

interface FeedbackFormValues {
    activityRating: number;
    activityFeedback: string;
    counterpartRating: number;
    maintainContact: boolean;
    isMentor: boolean;
}

const UpcomingActivitiesUser: React.FC<UpcomingActivitiesUserProps> = ({ onRefetch }) => {
    const dispatch = useDispatch();
    const userPersonalData = useSelector(selectPersonalData);
    const userId = userPersonalData?.Id;
    const [reload, setReload] = useState(false);
    const [selectedActivityId, setSelectedActivityId] = useState<number | null>(null);
    const [selectedAvailableTimeId, setSelectedAvailableTimeId] = useState<number | null>(null);
    const [openFeedbackDialog, setOpenFeedbackDialog] = useState(false);
    const [openConfirm, setOpenConfirm] = useState(false);
    const [counterpartName, setCounterpartName] = useState("");
    const [isMentor, setIsMentor] = useState(false);
    const [currentMenteeName, setCurrentMenteeName] = useState("");

    const { data: activities = [], isLoading: isLoadingActivities, refetch, error: activitiesError } = useGetUpcomingActivitiesByUserQuery(userId);
    const [sendActivityFeedback] = useSendActivityFeedbackMutation();
    const [cancelUpcomingActivity] = useCancelUpcomingActivityByUserMutation();

    useEffect(() => {
        if (reload) {
            refetch();
            setReload(false);
        }
    }, [reload, refetch]);

    useEffect(() => {
        refetch();
    }, []);

    useEffect(() => {
        if (onRefetch) {
            onRefetch(refetch);
        }
    }, [onRefetch, refetch]);

    const handleEvaluation = (id: number, availableTimeId: number, mentorName: string, menteeName: string, roleInActivity: string) => {
        setSelectedActivityId(id);
        setSelectedAvailableTimeId(availableTimeId);
        const isMentorRole = roleInActivity === 'Mentor';
        setCounterpartName(isMentorRole ? menteeName : mentorName);
        setIsMentor(isMentorRole);
        setCurrentMenteeName(menteeName);
        setOpenFeedbackDialog(true);
    };

    const handleFeedbackSubmit = async (values: FeedbackFormValues) => {
        if (selectedAvailableTimeId !== null) {
            const feedbackData = {
                ...values,
                availableTimeId: selectedAvailableTimeId,
                isMentor
            };

            let new_alert = { id: `sending_feedback_${Date.now()}`, type: "loading", title: "Enviando feedback", desc: "", close: false, timeout: 0 };
            dispatch(addAlert({ alert: new_alert }));

            try {
                await sendActivityFeedback(feedbackData).unwrap();
                dispatch(deleteAlert({ alert_id: new_alert.id }));
                let new_alert2 = { id: `feedback_sent_${Date.now()}`, type: "success", title: "Feedback enviado", desc: "El feedback ha sido enviado exitosamente", close: true, timeout: 0 };
                dispatch(addAlert({ alert: new_alert2 }));
                setReload(true);
            } catch (error) {
                dispatch(deleteAlert({ alert_id: new_alert.id }));
                let new_alert2 = { id: `feedback_error_${Date.now()}`, type: "error", title: "Error al enviar feedback", desc: "Hubo un error al enviar el feedback", close: true, timeout: 0 };
                dispatch(addAlert({ alert: new_alert2 }));
            }
        }
        setOpenFeedbackDialog(false);
    };

    const handleCancel = (id: number) => {
        setSelectedActivityId(id);
        setOpenConfirm(true);
    };

    const confirmCancel = async () => {
        if (selectedActivityId === null) return;

        let new_alert = { id: `canceling_activity_${Date.now()}`, type: "loading", title: "Cancelando actividad", desc: "", close: false, timeout: 0 };
        dispatch(addAlert({ alert: new_alert }));

        try {
            await cancelUpcomingActivity({ activityId: selectedActivityId, userId }).unwrap();
            dispatch(deleteAlert({ alert_id: new_alert.id }));
            let new_alert2 = { id: `activity_canceled_${Date.now()}`, type: "success", title: "Actividad cancelada", desc: "La actividad ha sido cancelada exitosamente", close: true, timeout: 0 };
            dispatch(addAlert({ alert: new_alert2 }));
            setReload(true);
        } catch (error) {
            dispatch(deleteAlert({ alert_id: new_alert.id }));
            let new_alert2 = { id: `cancel_activity_error_${Date.now()}`, type: "error", title: "Error al cancelar", desc: "Hubo un error al cancelar la actividad", close: true, timeout: 0 };
            dispatch(addAlert({ alert: new_alert2 }));
        } finally {
            setOpenConfirm(false);
            setSelectedActivityId(null);
        }
    };

    const generateActionButtons = (activity: Activity): ActionButtonProps<Activity>[] => {
        const { statusId, hasMenteeFeedback, hasMentorFeedback, roleInActivity } = activity;
        const isDisabledEvaluation = statusId === 4 && (
            (roleInActivity === 'Mentor' && hasMentorFeedback) ||
            (roleInActivity === 'Mentee' && hasMenteeFeedback)
        );

        const actionButtons: ActionButtonProps<Activity>[] = [];

        if (statusId === 3) {
            actionButtons.push({
                label: 'Cancelar',
                color: 'error',
                onClick: () => handleCancel(activity.id),
            });
        }

        if (statusId === 4 && !isDisabledEvaluation) {
            actionButtons.push({
                label: 'Evaluar',
                color: 'primary',
                onClick: () => handleEvaluation(
                    activity.id,
                    activity.availableTimeId,
                    activity.mentorName,
                    activity.menteeName,
                    activity.roleInActivity || ''
                ),
            });
        }

        return actionButtons;
    };

    const formattedActivities = activities.map((activity: Activity) => ({
        id: activity.id,
        Evento: activity.eventName,
        Actividad: activity.activityName,
        Fecha: `${moment(activity.startDate).format('DD/MM/YYYY HH:mm')} - ${moment(activity.endDate).format('DD/MM/YYYY HH:mm')}`,
        Contraparte: activity.roleInActivity === 'Mentor' ? activity.menteeName : activity.mentorName,
        Estado: activity.statusName,
        actionButtons: generateActionButtons(activity),
        statusId: activity.statusId,
        hasMenteeFeedback: activity.hasMenteeFeedback,
        hasMentorFeedback: activity.hasMentorFeedback,
        roleInActivity: activity.roleInActivity,
        mentorName: activity.mentorName,
        menteeName: activity.menteeName,
        availableTimeId: activity.availableTimeId,
    }));

    const columnsToShow = ['Evento', 'Actividad', 'Fecha', 'Contraparte', 'Estado'];

    return isLoadingActivities ? (
        <div className='table-container-loading'>
            <p>Cargando actividades...</p>
        </div>
    ) : activitiesError ? (
        <div className='table-container'>
            <p>No hay actividades próximas disponibles.</p>
        </div>
    ) : (
        <div className='card-container' style={{ width: '100%', paddingBottom: '80px', minHeight: '500px' }}>
            <GenericCardList<Activity>
                columns={columnsToShow}
                data={formattedActivities}
            />
            <FeedbackDialog
                open={openFeedbackDialog}
                onClose={() => setOpenFeedbackDialog(false)}
                onSubmit={handleFeedbackSubmit}
                counterpartName={counterpartName}
                isMentor={isMentor}
            />
            {/* Reemplazar el Dialog existente con ConfirmationDialog */}
            <ConfirmationDialog
                open={openConfirm}
                title="Confirmación"
                message="Recuerda que si cancelas la actividad podría no haber cupos disponibles en otro horario. ¿Deseas cancelar la actividad?"
                onAccept={confirmCancel}
                onCancel={() => setOpenConfirm(false)}
            />
        </div>
    );
};

export default UpcomingActivitiesUser;
