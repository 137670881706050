

import React from 'react';
import { Card, CardContent, Typography, CardActions, Button, CircularProgress } from '@mui/material';
import './GenericCard.scss';


export interface ActionButtonProps<T> {
  label: string;
  color: 'primary' | 'secondary' | 'inherit' | 'error' | 'info' | 'success' | 'warning';
  onClick: (data: T) => void;
  isLoading?: boolean;
  disabled?: boolean; 
}

interface GenericCardProps<T> {
  title?: string;
  columns: string[];
  data: T;
  actionButtons?: ActionButtonProps<T>[];
  isLoading?: boolean;
}

const GenericCard = <T,>({
  title,
  columns,
  data,
  actionButtons = [],
  isLoading = false,
}: GenericCardProps<T>) => {
  return (
    <Card className="generic-card" elevation={3}>
      {title && (
        <Typography variant="h6" className="generic-card__header">
          {title}
        </Typography>
      )}
      <CardContent className="generic-card__content">
        {columns.map((column) => {
          if (column === "action") return null;

          return (
            <Typography
              key={column}
              variant="body2"
              className={`generic-card__text generic-card__text--${column}`}
            >
              <strong>{column}:</strong>{' '}
              {String(data[column as keyof T]) || 'N/A'}
            </Typography>
          );
        })}
      </CardContent>
      {actionButtons.length > 0 && (
        <CardActions className="generic-card__footer">
          {actionButtons.map((button, index) => (
            <Button
              key={index}
              size="small"
              variant="contained"
              color={button.color}
              onClick={() => button.onClick(data)}
              className="generic-card__action-button"
              disabled={button.disabled || button.isLoading || isLoading} 
            >
              {button.isLoading || isLoading ? (
                <CircularProgress size={24} color="inherit" />
              ) : (
                button.label
              )}
            </Button>
          ))}
        </CardActions>
      )}
    </Card>
  );
};

export default GenericCard;
