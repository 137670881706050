import React, { useState, useRef } from 'react';
import { Box } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid';
import moment from 'moment';
import Tabla from '../../components/Tabla/Tabla';
import BlockScheduleContactWheelForm from './BlockScheduleContactWheelForm';
import { useGetAvailableTimesContactWheelQuery } from '../../redux/features/events/eventsApiSlice';  

type BlockSchedule = {
    activityId: number;  
    eventName: string;
    activityName: string;
    activityType: string;
    startTime: string;
    endTime: string;
    mentorsCount: number;
    mentors: any[];
    mentees: any[];
};

const ContactWheelTable: React.FC = () => {
    const { data: tableData = [], error, isLoading, refetch } = useGetAvailableTimesContactWheelQuery({});

    const [page, setPage] = useState<number>(0);
    const [pageSize, setPageSize] = useState<number>(10);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedBlock, setSelectedBlock] = useState<BlockSchedule | null>(null);

 
    const modifyButtonRef = useRef<HTMLButtonElement>(null);

    const handleEditClick = (blockSchedule: BlockSchedule) => {
        setSelectedBlock(blockSchedule); 
        setIsModalOpen(true);  
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);  
        setSelectedBlock(null);  

      
        if (modifyButtonRef.current) {
            modifyButtonRef.current.focus();
        }
    };

    const handleSubmitForm = (values: any) => {
        handleCloseModal();  
    };

    const handleSuccess = async () => {
      
        const { data: updatedData } = await refetch(); 
        if (updatedData) {
            const updatedBlock = updatedData.find((block: BlockSchedule) => block.activityId === selectedBlock?.activityId);
            if (updatedBlock) {
                setSelectedBlock(updatedBlock);
            }
        }
    };

    const columnas: GridColDef[] = [
        { headerName: "ID", field: "activityId", flex: 0.5, minWidth: 80 },
        { headerName: "Evento", field: "eventName", flex: 1, minWidth: 150 },
        { headerName: "Actividad", field: "activityType", flex: 1, minWidth: 150 },
        { headerName: "Temática", field: "activityName", flex: 1, minWidth: 150 },
        {
            headerName: "Inicio", field: "startTime", flex: 1, minWidth: 130,
            renderCell: (cellvalues) => (
                <div className='d-flex flex-column justify-content-center align-items-center'>
                    <div>{moment(cellvalues.value).format("DD-MM-YYYY")}</div>
                    <div>{moment(cellvalues.value).format("HH:mm")}</div>
                </div>
            )
        },
        {
            headerName: "Fin", field: "endTime", flex: 1, minWidth: 130,
            renderCell: (cellvalues) => (
                <div className='d-flex flex-column justify-content-center align-items-center'>
                    <div>{moment(cellvalues.value).format("DD-MM-YYYY")}</div>
                    <div>{moment(cellvalues.value).format("HH:mm")}</div>
                </div>
            )
        },
        {
            headerName: "Cantidad expertos", field: "mentorsCount", flex: 1, minWidth: 150, 
        },
        {
            headerName: "Opciones", field: "opciones", flex: 1, minWidth: 120, maxWidth: 150,
            renderCell: (cellvalues) => (
                <div className='d-flex flex-column justify-content-center align-items-stretch w-100'>
                    <button
                        className="btn btn-secondary btn-block p-1"
                        onClick={() => handleEditClick(cellvalues.row)}
                        ref={modifyButtonRef}
                    >
                        Modificar
                    </button>
                </div>
            )
        }
    ];

    if (isLoading) {
        return <div>Cargando...</div>;  
    }

    if (error) {
        if ('data' in error) {
            return <div>Error al cargar los datos: {JSON.stringify(error.data)}</div>;
        }
        if ('message' in error) {
            return <div>Error al cargar los datos: {error.message}</div>;
        }
        return <div>Error </div>;
    }

    return (
        <div className='table-container'>
            <Box sx={{ marginBottom: 2 }}>
                <Tabla
                    columnas={columnas}
                    datos={tableData}  
                    pageSize={pageSize}
                    setPageSize={setPageSize}
                    page={page}
                    onPageChange={setPage}
                    getRowId={(row: BlockSchedule) => row.activityId}   
                />
            </Box>

            {isModalOpen && selectedBlock && (
                <BlockScheduleContactWheelForm
                    handleSubmit={handleSubmitForm}   
                    handleClose={handleCloseModal}   
                    isSubmitting={false}             
                    initialValues={selectedBlock}   
                    onSuccess={handleSuccess}
                />
            )}
        </div>
    );
};

export default ContactWheelTable;
