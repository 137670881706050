import './Navbar.scss'
import { Link, useLocation } from 'react-router-dom';
import MsjButton from "./MsjButton/MsjButton";
import UserButton from './UserButton/UserButton';
import { useSelector } from "react-redux";
import Navegacion from './Navegacion';
import { selectPersonalData, selectPublicData } from '../../../redux/features/user/userSlice';
import LanguageButton from './LanguageButton/LanguageButton';
import { selectCurrentDictonary } from '../../../redux/features/language/LanguageSlice';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import NavbarItem from './NavbarItem';


function Navbar() { /* onZoomMeeting = false */
    const user_personal_data = useSelector(selectPersonalData)
    // const user_id = user_personal_data.Id
    let logoEtm = "/logo-etm.png"
    let logoEtm_chico = "/logo-chico-etm-blanco.png"
    let go_to_home = "/home"

    const textNavbar = useSelector(selectCurrentDictonary).navbar1

    const location = useLocation();
    const onZoomMeeting = location.pathname == "/zoom-meeting" || location.pathname == "/meeting"

    const user_public_data = useSelector(selectPublicData)

    const handleHelpClick = () => {
        const pdfFile = textNavbar.menu1 === "Home" ? "ETMmeet - User manual.pdf" : "ETMmeet - Manual de usuario.pdf";

        window.open(`${process.env.PUBLIC_URL}/media/manualUsuario/${pdfFile}`, '_blank', 'noopener,noreferrer');
    };
    

    let navbar_right_user = <>
        <div className='mr-1'>
            <LanguageButton ></LanguageButton>
        </div>
    </>
    let navegacion = < ></>
    if (user_personal_data) {
        navbar_right_user = <>
            <div className='mr-1'>
            <LanguageButton ></LanguageButton>
            </div>
            <div className='mr-1'>
                <MsjButton user_personal_data={user_personal_data} ></MsjButton>
            </div>
            <div className='mr-1'>
                <UserButton user_public_data={user_public_data} user_personal_data={user_personal_data}></UserButton>
            </div>
        </>
        navegacion = <Navegacion ></Navegacion>
    } else {
        go_to_home = "/"
        navbar_right_user = <>
            <div className='mr-1'>
                <LanguageButton ></LanguageButton>
            </div>
            <div className='mr-1'>
            <NavbarItem handleOnClick={handleHelpClick} IconImage={HelpOutlineIcon} />
        </div>
            <div className='mr-2'>
                <Link to="/sign-in" className='btn btn-sm btn-rounded btn-outline-primary'>{textNavbar.btn_login}</Link>
            </div>
            <div className=''>
                <Link to="/sign-up" className='btn btn-sm btn-rounded btn-primary'>{textNavbar.btn_signup}</Link>
            </div>
            
        </>
        navegacion = < ></>
    }


    let logo_html = <>
        <Link to={"/"} className="logo-principal-container d-block d-md-none">
            <img className='logo-principal' src={process.env.PUBLIC_URL + logoEtm_chico} alt="ETM" />
        </Link>
        <Link to={"/"} className="logo-principal-container d-none d-md-block mr-4">
            <img className='logo-principal' src={process.env.PUBLIC_URL + logoEtm} alt="ETM" />
        </Link>
    </>

    if (onZoomMeeting) {

        let logo_html_zoom = <>
            <div className="logo-principal-container d-block d-md-none">
                <img className='logo-principal' src={process.env.PUBLIC_URL + logoEtm_chico} alt="ETM" />
            </div>
            <div className="logo-principal-container d-none d-md-block mr-4">
                <img className='logo-principal' src={process.env.PUBLIC_URL + logoEtm} alt="ETM" />
            </div>
        </>

        return (
            <div></div>
        )
        return (
                

            < div className="navbar fixed-top padding-layout-x  " >
                <div className='d-flex justify-content-between justify-content-between align-items-center w-100'>
                    <div >
                        <div className='d-flex justify-content-start align-items-center'>
                            {logo_html_zoom}
                        </div>
                    </div>
                    <div className=''>
                    </div>

                </div>

            </div >

        )
    }
    return (
        <div className="navbar fixed-top padding-layout-x  ">
            <div className='d-flex justify-content-between justify-content-between align-items-center w-100'>
                <div >
                    <div className='d-flex justify-content-start align-items-center'>
                        {logo_html}
                        {navegacion}
                    </div>
                </div>
                <div className=''>
                    <div className='d-flex justify-content-end align-items-center'>
                        {navbar_right_user}
                    </div>
                </div>

            </div>

        </div>


    )
}

export default Navbar;