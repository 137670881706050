import React, { useState } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, IconButton } from '@mui/material';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import ThumbDownIcon from '@mui/icons-material/ThumbDown';
import Rating from 'react-rating-stars-component';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';

interface FeedbackFormProps {
    open: boolean;
    onClose: () => void;
    onSubmit: (values: FeedbackFormValues) => void;
    counterpartName: string;
    isMentor: boolean;
}

interface FeedbackFormValues {
    activityRating: number;
    activityFeedback: string;
    counterpartRating: number;
    maintainContact: boolean;
    availableTimeId?: number;
    isMentor: boolean;
}

const validationSchema = Yup.object({
    activityRating: Yup.number().required('Campo obligatorio').min(1, 'Campo obligatorio'),
    counterpartRating: Yup.number().required('Campo obligatorio').min(1, 'Campo obligatorio'),
    activityFeedback: Yup.string().max(500, 'Máximo 500 caracteres'),
    maintainContact: Yup.boolean().required('Campo obligatorio')
});

const FeedbackDialog: React.FC<FeedbackFormProps> = ({ open, onClose, onSubmit, counterpartName, isMentor }) => {
    const initialValues: FeedbackFormValues = {
        activityRating: 0,
        activityFeedback: '',
        counterpartRating: 0,
        maintainContact: false,
        isMentor
    };

    const [confirmOpen, setConfirmOpen] = useState(false);
    const [formValues, setFormValues] = useState<FeedbackFormValues | null>(null);

    const handleClose = (event: any, reason: string) => {
        if (reason !== 'backdropClick') {
            onClose();
        }
    };

    const handleFormSubmit = (values: FeedbackFormValues) => {
        setFormValues(values);
        setConfirmOpen(true);
    };

    const handleConfirm = () => {
        if (formValues) {
            onSubmit(formValues);
        }
        setConfirmOpen(false);
        onClose();
    };

    return (
        <>
            <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth disableEscapeKeyDown>
                <DialogTitle>Feedback</DialogTitle>
                <DialogContent>
                    <Formik
                        initialValues={initialValues}
                        validationSchema={validationSchema}
                        onSubmit={(values, { setSubmitting }) => {
                            handleFormSubmit(values);
                            setSubmitting(false);
                        }}
                    >
                        {({ isSubmitting, setFieldValue, values }) => (
                            <Form noValidate autoComplete="off">
                                <div className="form-group">
                                    <label className="label label-primary m-0 p-0">
                                        ¿Cuántas estrellas le darías a la actividad? <span className="text-danger">*</span>
                                    </label>
                                    <Rating
                                        count={5}
                                        value={values.activityRating}
                                        onChange={(newValue: number) => setFieldValue('activityRating', newValue)}
                                        size={24}
                                        activeColor="#ffd700"
                                    />
                                    <ErrorMessage name="activityRating" component="div" className="text-danger" />
                                </div>
                                <div className="form-group">
                                    <label className="label label-primary m-0 p-0">
                                        ¿Cuántas estrellas le darías a {counterpartName}? <span className="text-danger">*</span>
                                    </label>
                                    <Rating
                                        count={5}
                                        value={values.counterpartRating}
                                        onChange={(newValue: number) => setFieldValue('counterpartRating', newValue)}
                                        size={24}
                                        activeColor="#ffd700"
                                    />
                                    <ErrorMessage name="counterpartRating" component="div" className="text-danger" />
                                </div>
                                <div className="form-group">
                                    <label className="label label-primary m-0 p-0">
                                        ¿Te gustaría mantener el contacto de {counterpartName}? <span className="text-danger">*</span>
                                    </label>
                                    <div>
                                    <IconButton
                                            onClick={() => setFieldValue('maintainContact', true)}
                                            sx={{
                                                color: values.maintainContact ? '#ffffff' : '#9e9e9e',
                                                backgroundColor: values.maintainContact ? '#1e88e5' : '#e0e0e0',
                                                '&:hover': { backgroundColor: '#1565c0' },
                                                border: `1px solid ${values.maintainContact ? '#1565c0' : '#9e9e9e'}`,
                                                borderRadius: '8px',
                                                padding: '8px',
                                                marginRight: '8px',
                                            }}
                                        >
                                            <ThumbUpIcon />
                                        </IconButton>
                                        <IconButton
                                            onClick={() => setFieldValue('maintainContact', false)}
                                            sx={{
                                                color: !values.maintainContact ? '#ffffff' : '#9e9e9e',
                                                backgroundColor: !values.maintainContact ? '#1e88e5' : '#e0e0e0',
                                                '&:hover': { backgroundColor: '#1565c0' },
                                                border: `1px solid ${!values.maintainContact ? '#1565c0' : '#9e9e9e'}`,
                                                borderRadius: '8px',
                                                padding: '8px',
                                                marginLeft: '8px',
                                            }}
                                        >
                                            <ThumbDownIcon />
                                        </IconButton>

                                    </div>
                                    <ErrorMessage name="maintainContact" component="div" className="text-danger" />
                                </div>
                                <div className="form-group">
                                    <label className="label label-primary m-0 p-0">
                                        Deja aquí tus comentarios
                                    </label>
                                    <Field
                                        as="textarea"
                                        className="form-control"
                                        rows={4}
                                        maxLength={500}
                                        name="activityFeedback"
                                    />
                                    <div className="text-muted">Máximo 500 caracteres</div>
                                    <ErrorMessage name="activityFeedback" component="div" className="text-danger" />
                                </div>
                                <DialogActions>
                                    <Button onClick={onClose} className="btn btn-outline-danger mr-2">CANCELAR</Button>
                                    <Button type="submit" className="btn btn-primary" disabled={isSubmitting}>EVALUAR</Button>
                                </DialogActions>
                            </Form>
                        )}
                    </Formik>
                </DialogContent>
            </Dialog>
            {confirmOpen && (
                <Dialog
                    open={confirmOpen}
                    onClose={() => setConfirmOpen(false)}
                >
                    <DialogTitle>Confirmación</DialogTitle>
                    <DialogContent>
                        ¿Estás seguro de enviar el feedback?
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => setConfirmOpen(false)} color="primary">Cancelar</Button>
                        <Button onClick={handleConfirm} color="primary">Confirmar</Button>
                    </DialogActions>
                </Dialog>
            )}
        </>
    );
};

export default FeedbackDialog;
