// src/components/ConfirmationDialog.tsx

import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Typography } from '@mui/material';
import './ConfirmationDialog.scss'; // Importar los estilos

interface ConfirmationDialogProps {
    open: boolean;
    title?: string;
    message: string;
    onAccept: () => void;
    onCancel: () => void;
}

const ConfirmationDialog: React.FC<ConfirmationDialogProps> = ({
    open,
    title = 'Confirmación',
    message,
    onAccept,
    onCancel,
}) => {
    return (
        <Dialog
            open={open}
            onClose={onCancel}
            aria-labelledby="confirmation-dialog-title"
            className="confirmation-dialog"
        >
            <DialogTitle id="confirmation-dialog-title">{title}</DialogTitle>
            <DialogContent>
                <Typography>{message}</Typography>
            </DialogContent>
            <DialogActions>
                <Button onClick={onCancel} color="error" variant="outlined">
                    Cancelar
                </Button>
                <Button onClick={onAccept} color="primary" variant="contained">
                    Aceptar
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default ConfirmationDialog;
