// src/components/ActivityDayTableUser.tsx

import React, { useState, useEffect } from 'react';
import {
  Box,
  Select,
  MenuItem,
  FormControl,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  SelectChangeEvent,
  CircularProgress
} from '@mui/material';
import moment from 'moment';
import { useSelector, useDispatch } from "react-redux";
import {
  useGetActivityAvailableTimesMenteeQuery,
  useAssignMenteeToBlockMutation
} from '../../redux/features/events/eventsApiSlice';
import ActivityCards from '../../components/ActivityCards/ActivityCards';
import { selectPersonalData } from '../../redux/features/user/userSlice';
import './ActivityDayTableUser.scss';
import GenericCardList, { ActionButtonProps } from '../../components/GenericCard/GenericCardList';
import { addAlert, deleteAlert } from '../../redux/features/alerts/alertsSlice';
import { Activity, ActivityDayTableUserProps, ActivitiesTicketData, Block } from '../../types/types';

const ActivityDayTableUser: React.FC<ActivityDayTableUserProps> = ({
  selectedEvent,
  activitiesTicketData,
  handleViewSchedules,
  userState,
  pack
}) => {
  console.log(`ActivityDayTableUser - userState: ${userState}`);
  const dispatch = useDispatch();
  const userPersonalData = useSelector(selectPersonalData);
  const userId = userPersonalData?.Id;

  const [tableData, setTableData] = useState<Activity[]>([]);
  const [filteredData, setFilteredData] = useState<Activity[]>([]);

  const [selectedActivity, setSelectedActivity] = useState<string>('');
  const [selectedTheme, setSelectedTheme] = useState<string>('');
  const [selectedStartTime, setSelectedStartTime] = useState<string>('');
  const [selectedEndTime, setSelectedEndTime] = useState<string>('');
  const [selectedMentor, setSelectedMentor] = useState<string>('');

  const [open, setOpen] = useState(false);
  const [activityId, setActivityId] = useState<number | null>(null);
  const [schedules, setSchedules] = useState<Block[]>([]);
  const [assigningBlockId, setAssigningBlockId] = useState<number | null>(null);

  const [isLoadingSchedules, setIsLoadingSchedules] = useState<boolean>(true);

  const { data: schedulesData, error: schedulesError, refetch: refetchSchedules, isFetching: isFetchingSchedules } = useGetActivityAvailableTimesMenteeQuery(activityId ?? -1, {
    skip: activityId === null,
    refetchOnMountOrArgChange: true,
  });

  const [assignMenteeToBlock] = useAssignMenteeToBlockMutation();

  useEffect(() => {
    // Este efecto puede ser utilizado para otras inicializaciones si es necesario
  }, []);

  useEffect(() => {
    if (schedulesData) {
      const availableBlocks = schedulesData.filter((block: Block) => block.userMentee === null);
      setSchedules(availableBlocks);
      setIsLoadingSchedules(false);
    } else if (schedulesError) {
      setIsLoadingSchedules(false);
      let errorMessage = 'Error al cargar los horarios disponibles.';
      if ('status' in schedulesError) {
        errorMessage += ` Código de error: ${schedulesError.status}`;
      }
      let new_alert = {
        id: `error_fetching_schedules_${Date.now()}`,
        type: "error",
        title: "Error al Cargar Horarios",
        desc: errorMessage,
        close: true,
        timeout: 4000
      };
      dispatch(addAlert({ alert: new_alert }));
    } else {
      setIsLoadingSchedules(true);
    }
  }, [schedulesData, schedulesError, dispatch]);

  const handleCloseModal = () => {
    setOpen(false);
    setActivityId(null);
  };

  useEffect(() => {
    if (activitiesTicketData?.activities) {
      const filteredByEvent = activitiesTicketData.activities
        .filter((activity: Activity) => activity.eventId === selectedEvent);

      setTableData(filteredByEvent);
      filterData(filteredByEvent);
    }
  }, [activitiesTicketData, selectedEvent, selectedActivity, selectedTheme, selectedStartTime, selectedEndTime, selectedMentor]);

  const filterData = (data: Activity[]) => {
    let filtered = data;

    if (selectedActivity) {
      filtered = filtered.filter((item: Activity) => item.type === selectedActivity);
    }
    if (selectedTheme) {
      filtered = filtered.filter((item: Activity) => item.activityName === selectedTheme);
    }
    if (selectedMentor) {
      filtered = filtered.filter((item: Activity) =>
        item.mentors && item.mentors.includes(selectedMentor)
      );
    }

    if (selectedStartTime || selectedEndTime) {
      let filterStart = selectedStartTime ? moment(selectedStartTime, "HH:mm") : null;
      let filterEnd = selectedEndTime ? moment(selectedEndTime, "HH:mm") : null;

      if (filterStart && filterEnd && filterEnd.isBefore(filterStart)) {
        const temp = filterStart.clone();
        filterStart = filterEnd.clone();
        filterEnd = temp.clone();
      }

      filtered = filtered.filter((item: Activity) => {
        const activityStart = moment(item.startTime, "HH:mm");
        const activityEnd = moment(item.endTime, "HH:mm");

        let isStartInRange = true;
        let isEndInRange = true;

        if (filterStart) {
          isStartInRange = activityStart.isSameOrAfter(filterStart);
        }

        if (filterEnd) {
          isEndInRange = activityEnd.isSameOrBefore(filterEnd);
        }

        return isStartInRange && isEndInRange;
      });
    }
    setFilteredData(filtered);
  };

  const handleChangeFilter = (
    setFilter: React.Dispatch<React.SetStateAction<string>>
  ) => (
    event: SelectChangeEvent<string>,
    child: React.ReactNode
  ) => {
      const value = event.target.value;
      setFilter(value);
    };

  const getFilteredOptions = (key: keyof Activity, data: Activity[]) => {
    return Array.from(new Set(data.map(item => item[key])))
      .filter(option => option !== null && option !== '') as string[];
  };

  const getUniqueMentors = (data: Activity[]) => {
    const mentors = data.flatMap(activity =>
      activity.mentors ? activity.mentors : []
    );
    return Array.from(new Set(mentors)).sort((a, b) => a.localeCompare(b));
  };

  const handleInscriptionClick = (activity: Activity) => {
    handleViewSchedules(activity.activityId);
    setActivityId(activity.activityId);
    setOpen(true);
  };

  const handleAssignMentee = async (blockId: number) => {
    console.log(blockId)
    setAssigningBlockId(blockId);
    let new_alert = {
      id: `assigning_mentee_${Date.now()}`,
      type: "info",
      title: "Registrando bloque de actividad",
      desc: "Por favor, espera mientras registramos tu bloque de actividad.",
      close: false,
      timeout: 0
    };

    console.log("new_alert:", new_alert);
    dispatch(addAlert({ alert: new_alert }));

    try {
      await assignMenteeToBlock({ menteeId: userId, blockId }).unwrap();
      dispatch(deleteAlert({ alert_id: new_alert.id }));
      let new_alert2 = {
        id: `mentee_assigned_${Date.now()}`,
        type: "success",
        title: "Bloque Registrado",
        desc: "El bloque de la actividad ha sido registrado con éxito.",
        close: true,
        timeout: 2000
      };
      dispatch(addAlert({ alert: new_alert2 }));
      refetchSchedules();
      setOpen(false);
    } catch (error: any) {
      console.error("Error al asignar mentee:", error);
      dispatch(deleteAlert({ alert_id: new_alert.id }));
      let new_alert2 = {
        id: `assign_mentee_error_${Date.now()}`,
        type: "error",
        title: "Error al Registrar el Bloque",
        desc: "Hubo un error al registrar el bloque. Por favor, intenta nuevamente.",
        close: true,
        timeout: 4000
      };
      dispatch(addAlert({ alert: new_alert2 }));
    } finally {
      setAssigningBlockId(null);
    }
  };

  const capitalizeWords = (str: string): string => {
    return str.replace(/\b\w/g, char => char.toUpperCase()).toLowerCase().replace(/(?:^|\s)\S/g, char => char.toUpperCase());
  };

  // Ajustamos la función para permitir registro solo cuando userState es 3
  const canRegisterForBlock = (block: any) => {
    return userState === 3 && !(block.statusId === 3 || block.statusId === 5);
  };

  // Modificamos generateActionButtons para incluir el botón solo cuando userState es 3
  const generateActionButtons = (item: any): ActionButtonProps<any>[] => {
    if (userState !== 3) {
      return []; // No mostramos ningún botón si userState no es 3
    }
    return [
      {
        label: 'Inscribirse',
        color: 'primary',
        onClick: () => handleAssignMentee(item.blockId),
        isLoading: assigningBlockId === item.blockId,
        disabled: !canRegisterForBlock(item)
      },
    ];
  };

  const prepareDataForDialog = () => {
    const data = schedules.map(block => {
      const item = {
        id: block.id,
        "Rango Horario": `${moment(block.startTime, "HH:mm").format("HH:mm")} - ${moment(block.endTime, "HH:mm").format("HH:mm")}`,
        "Duración": `${block.duration} minutos`,
        "Mentor": block.userMentor
          ? `${capitalizeWords(block.userMentor.name)} ${capitalizeWords(block.userMentor.lastName)}`
          : 'N/A',
        "Industrias": block.userMentor
          ? block.userMentor.industries.map((ind: string) => capitalizeWords(ind)).join(', ')
          : 'N/A',
        blockId: block.id,
        actionButtons: [] as ActionButtonProps<any>[],
      };
      item.actionButtons = generateActionButtons(item);
      return item;
    });

    return data;
  };

  const dialogData = prepareDataForDialog();

  const dialogColumns: string[] = ["Rango Horario", "Duración", "Mentor", "Industrias"];

  return (
    <div className='activity-day-table-user-container'>
      <>
        <Box sx={{ marginBottom: 2, display: 'flex', flexWrap: 'wrap', gap: 2, alignItems: 'center' }}>
          {/* Filtros */}
          <FormControl variant="outlined" size="small" className="common-form-control">
            <Select
              value={selectedActivity}
              onChange={handleChangeFilter(setSelectedActivity)}
              displayEmpty
              renderValue={(value) => (value === '' ? 'Actividad' : (value as string))}
              inputProps={{ 'aria-label': 'Actividad' }}
            >
              <MenuItem value="">
                <em>Todas las actividades</em>
              </MenuItem>
              {getFilteredOptions('type', tableData)
                .sort((a, b) => a.localeCompare(b))
                .map((activityType, index) => (
                  <MenuItem key={index} value={activityType}>{activityType}</MenuItem>
                ))}
            </Select>
          </FormControl>

          <FormControl variant="outlined" size="small" className="common-form-control">
            <Select
              value={selectedTheme}
              onChange={handleChangeFilter(setSelectedTheme)}
              displayEmpty
              renderValue={(value) => (value === '' ? 'Temática' : (value as string))}
              inputProps={{ 'aria-label': 'Temática' }}
            >
              <MenuItem value="">
                <em>Todas las temáticas</em>
              </MenuItem>
              {getFilteredOptions('activityName', tableData)
                .sort((a, b) => a.localeCompare(b))
                .map((activityName, index) => (
                  <MenuItem key={index} value={activityName}>{activityName}</MenuItem>
                ))}
            </Select>
          </FormControl>

          <FormControl variant="outlined" size="small" className="common-form-control">
            <Select
              value={selectedStartTime}
              onChange={handleChangeFilter(setSelectedStartTime)}
              displayEmpty
              renderValue={(value) => value === '' ? 'Inicio Hora' : (value as string)}
              inputProps={{ 'aria-label': 'Inicio Hora' }}
            >
              <MenuItem value="">
                <em>Selecciona Inicio Hora</em>
              </MenuItem>
              {Array.from(new Set(filteredData.map(activity => moment(activity.startTime, "HH:mm").format("HH:mm"))))
                .sort((a, b) => moment(a, "HH:mm").diff(moment(b, "HH:mm")))
                .map((time, index) => (
                  <MenuItem key={index} value={time}>{time}</MenuItem>
                ))}
            </Select>
          </FormControl>

          <FormControl variant="outlined" size="small" className="common-form-control">
            <Select
              value={selectedEndTime}
              onChange={handleChangeFilter(setSelectedEndTime)}
              displayEmpty
              renderValue={(value) => value === '' ? 'Fin Hora' : (value as string)}
              inputProps={{ 'aria-label': 'Fin Hora' }}
            >
              <MenuItem value="">
                <em>Selecciona Fin Hora</em>
              </MenuItem>
              {Array.from(new Set(filteredData.map(activity => moment(activity.endTime, "HH:mm").format("HH:mm"))))
                .sort((a, b) => moment(a, "HH:mm").diff(moment(b, "HH:mm")))
                .map((time, index) => (
                  <MenuItem key={index} value={time}>{time}</MenuItem>
                ))}
            </Select>
          </FormControl>

          <FormControl variant="outlined" size="small" className="common-form-control">
            <Select
              value={selectedMentor}
              onChange={handleChangeFilter(setSelectedMentor)}
              displayEmpty
              renderValue={(value) => value === '' ? 'Experto' : (value as string)}
              inputProps={{ 'aria-label': 'Experto' }}
            >
              <MenuItem value="">
                <em>Todos los expertos</em>
              </MenuItem>
              {getUniqueMentors(tableData).map((mentor, index) => (
                <MenuItem key={index} value={mentor}>{mentor}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>

        <ActivityCards
          datos={filteredData}
          handleEditClick={handleInscriptionClick}
          isConfirmed={userState === 3}
        />

        <Dialog
          open={open}
          onClose={handleCloseModal}
          maxWidth="xs"
          fullWidth
          sx={{
            '& .MuiDialog-paper': {
              width: '90vw',
              maxWidth: 'none',
            },
          }}
        >
          <DialogTitle>Horarios y Bloques Disponibles</DialogTitle>
          <DialogContent>
            {isFetchingSchedules ? (
              <div className="loader-container">
                <CircularProgress size={50} aria-label="Cargando horarios disponibles..." />
                <Typography variant="body1">Cargando horarios disponibles...</Typography>
              </div>
            ) : (
              schedules.length === 0 ? (
                <Typography variant="body1">Actualmente, no hay horarios disponibles</Typography>
              ) : (
                <GenericCardList
                  columns={dialogColumns}
                  data={dialogData}
                  isLoading={assigningBlockId !== null}
                />
              )
            )}
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseModal} color="primary">Cerrar</Button>
          </DialogActions>
        </Dialog>
      </>

    </div>
  );
};

export default ActivityDayTableUser;
